import React from "react";
import Layout from "../../Components/layout";
import GetItems from "../../Components/getItems";
import { Seo } from "../../Components/seo";


export default function Onesie() {
  return (
    <Layout>
      <GetItems search="Kid's Swimsuit" title="Kid's Swimsuits" onesie />
    </Layout>
  )
}
export const Head = () => (<Seo pathname={'./kids/kswimsuit'} title="STM Quality Kid's Swimsuits: Positivity and Awareness" description={"Shopping for Kid's Swimsuits to spread positivity and awareness and promote Stronger Than Medicine (STM)."} />)